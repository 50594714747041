/* General Container */
.domain-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

.domain-heading {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

/* Columns Container */
.domain-columns {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

/* Individual Column */
.domain-column {
    width: 50%;
}

/* Category Container */
.domain-category {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

/* Category Title */
.domain-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    cursor: pointer;
}

.domain-icon {
    font-size: 1.5rem;
    margin-right: 10px;
    color: #4caf50;
}

/* Items List */
.domain-items {
    list-style: none;
    padding: 10px 0 0 20px;
    margin: 0;
}

.domain-item {
    font-size: 1rem;
    color: #555;
    margin: 5px 0;
    border-bottom: 1px solid #eee;
    padding-bottom: 5px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .domain-columns {
        flex-direction: column;
    }

    .domain-column {
        width: 100%;
    }
}
