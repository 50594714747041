/* Container for VisionM */
.VisionM-container-VM1 {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    position: relative;
    padding: 20px;
  }
  
  /* Image Banner Section */
  .image-section-VM1 {
    width: 100%;
    position: relative;
  }
  
  .VisionM-image-VM1 {
    width: 100%;
    height: 40vh;
    object-fit: cover;
  }
  
  .VisionM-heading-VM1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 90%;
  }
  
  /* Vision and Mission Section */
  .VisionM-content-VM1 {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .VisionM-box-VM1 {
    border: 2px solid black;
    padding: 20px;
    flex: 1;
    min-width: 300px;
  }
  
  .VisionM-box-header-VM1 {
    display: flex;
  align-items: center;
  margin-bottom: 10px;
  }
  
  .VisionM-logo-VM1 img {
    width: 40px;
  height: 40px;
  background-color: #FFF;
  margin-right: 20px;  /* Increased margin to create space */
  }
  
  .VisionM-subheading-VM1 {
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .VisionM-text-VM1 {
    font-size: 1rem;
    color: #555;
  }
  
  /* Values Section */
  .VisionM-values-VM1 {
    margin-top: 40px;
  }
  
  .VisionM-values-content-VM1 {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .VisionM-value-box-VM1 {
    flex: 1;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .VisionM-sub-box-VM1 {
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .VisionM-value-heading-VM1 {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .VisionM-value-text-VM1 {
    font-size: 1rem;
    color: #333;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .VisionM-main-heading-VM1 {
      font-size: 2rem;
    }
  
    .VisionM-subheading-VM1 {
      font-size: 1.2rem;
    }
  
    .VisionM-text-VM1 {
      font-size: 0.9rem;
    }
  }
