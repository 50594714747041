.footer-containerFTR2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  height: auto; /* Adjusted for flexibility */
}

.footer-sectionFTR2 {
  flex: 1 1 30%;
  min-width: 250px;
  padding: 10px;
}

.footer-emailFTR2 {
  color: #e8e40a;
}

.footer-headingFTR2 {
  font-size: 1.5rem;
  font-weight: bold;
  color: #229954;
  margin-bottom: 15px;
}

.footer-linksFTR2 {
  list-style: none;
  padding: 0;
}

.footer-linkFTR2 {
  text-decoration: none;
  color: #cdc4c4; /* White text */
  font-size: 1rem;
  display: block;
  margin-bottom: 10px;
}

.footer-linkFTR2:hover {
  color: #0d17d7;
  text-decoration: underline;
}

.footer-addressFTR2 p {
  font-size: 1rem;
  line-height: 1.6;
  color: #b6afaf; /* White text */
  margin: 0; /* Removed gaps between location lines */
}

.footer-bottomFTR2 {
  text-align: center;
  padding: 15px;
  width: 100%;
  font-size: 0.9rem;
  background-color: #119dd4; /* Black background */
  color: #fff;
  margin-top: -3px;
}

.footer-bottom-linkFTR2 {
  color: #cffd00;
  text-decoration: none;
}

.footer-bottom-linkFTR2:hover {
  text-decoration: underline;
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .footer-containerFTR2 {
    flex-direction: column; /* Stacks items vertically */
    align-items: center; /* Center content */
    height: auto; /* Allow the container to grow with content */
  }

  .footer-sectionFTR2 {
    flex: 1 1 100%; /* Make sections full width on mobile */
    text-align: center; /* Center content */
    padding: 15px; /* Add spacing around sections */
    margin-bottom: 20px; /* Space out sections */
  }

  .footer-headingFTR2 {
    font-size: 1.25rem; /* Adjust heading size for mobile */
    margin-bottom: 10px; /* Reduced space after headings */
  }

  .footer-linkFTR2 {
    font-size: 0.9rem; /* Smaller text for links */
  }

  .footer-bottomFTR2 {
    font-size: 0.8rem; /* Smaller font size for footer bottom */
    padding: 10px; /* Reduced padding for mobile */
  }

  .footer-bottom-linkFTR2 {
    font-size: 0.9rem; /* Adjust font size for link */
  }

  /* Adjust email link for mobile */
  .footer-emailFTR2 {
    font-size: 0.9rem;
    word-wrap: break-word;
  }
}
