/* Global Container */
.reg2-container {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    font-family: Arial, sans-serif;
}

/* Image Section */
.reg2-img-section {
    position: relative;
    width: 100%;
    height: 20vh; /* Adjusted height for smaller screens */
}

.reg2-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.reg2-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.reg2-text-overlay {
    font-size: 1.8rem;
    color: white;
    font-weight: bold;
    text-align: center;
}

/* Form Section */
.reg2-form-section {
    margin-top: 20px;
    padding: 10px;
}

.reg2-form-heading {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.reg2-form {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
}

.reg2-form-group {
    margin-bottom: 15px;
    width: 100%;
}

.reg2-label {
    display: block;
    font-size: 1rem;
    color: #555;
    margin-bottom: 5px;
}

.reg2-input, .reg2-select {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.reg2-input:focus, .reg2-select:focus {
    outline: none;
    border-color: #007bff;
}

.reg2-submit-btn {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
    font-weight: bold;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
}

.reg2-submit-btn:hover {
    background-color: #0056b3;
}

/* Row Styling */
.reg2-row {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
}

.reg2-row .reg2-form-group {
    flex: 1;
    min-width: calc(50% - 15px);
}

/* Responsive Design */
@media screen and (max-width: 768px) {
    .reg2-img-section {
        height: 15vh; /* Reduced height for smaller screens */
        width: 330px;
     
    }

    .reg2-text-overlay {
        font-size: 1.2rem;
        
        
    }

    .reg2-form-heading {
        font-size: 1.5rem;
        margin-bottom: 35px;
        width: 255px;

    }

    .reg2-input, .reg2-select {
        font-size: 1rem;
        width: 285px;
        margin-bottom: -3px;

    }

    .reg2-submit-btn {
        font-size: 1rem;
        width: 285px;

    }

    .reg2-row {
        flex-direction: column; /* Stacking items vertically for mobile view */
        gap: 10px;
    }

    .reg2-row .reg2-form-group {
        min-width: 100%;
    }
}
