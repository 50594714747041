.corporate-container-CT2 {
  font-family: Arial, sans-serif;
  padding: 50px;
}

/* Desktop-specific styles */
.corporate-banner-CT2 {
  position: relative;
  height: 20vh;
}

.banner-img-CT2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-heading-CT2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

.main-heading-CT2 {
  margin-top: 20px;
  font-size: 1.8rem;
}

.text-black-CT2 {
  color: black;
}

.text-blue-CT2 {
  color: blue;
}

.description-CT2 {
  margin: 10px 0;
  line-height: 1.6;
}

.benefits-heading-CT2 {
  margin-top: 40px;
  font-size: 1.5rem;
}

.benefits-container-CT2 {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.benefits-box-CT2 {
  width: 50%;
}

.benefit-item-CT2 {
  margin: 10px 0;
}

.benefit-item-blue-CT2 {
  margin: 10px 0;
  background-color: #283747;
  color: white;
  padding: 5px;
}

.why-Teamstaff-CT2 ul {
  margin: 20px 0;
  padding-left: 20px;
}

.soft-skill-heading-CT2 {
  margin-top: 40px;
  font-size: 1.5rem;
}

.soft-skill-container-CT2 {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.soft-skill-column-CT2 {
  width: 33.33%;
}

.soft-skill-img-CT2 img {
  width: 90%;
  height: 45vh;
  border-radius: 15px;
}

/* Mobile and Tablet view adjustments */
@media (max-width: 768px) {
  .corporate-container-CT2 {
    padding: 20px;
  }

  .corporate-banner-CT2 {
    height: 30vh;
  }

  .banner-heading-CT2 {
    font-size: 1.5rem;
  }

  .main-heading-CT2 {
    font-size: 1.5rem;
  }

  .description-CT2 {
    font-size: 0.9rem;
  }

  .benefits-container-CT2 {
    flex-direction: column;
    gap: 10px;
  }

  .benefits-box-CT2 {
    width: 100%;
  }

  .benefit-item-CT2,
  .benefit-item-blue-CT2 {
    font-size: 0.9rem;
  }

  .why-Teamstaff-CT2 ul {
    padding-left: 15px;
  }

  .soft-skill-container-CT2 {
    flex-direction: column;
    gap: 10px;
  }

  .soft-skill-column-CT2 {
    width: 100%;
  }

  .soft-skill-img-CT2 img {
    width: 100%;
    height: auto;
  }
}

/* Mobile View adjustments */
@media (max-width: 480px) {
  .corporate-banner-CT2 {
    height: 40vh;
  }

  .banner-heading-CT2 {
    font-size: 1.2rem;
  }

  .main-heading-CT2 {
    font-size: 1.2rem;
  }

  .description-CT2 {
    font-size: 0.85rem;
  }

  .soft-skill-img-CT2 img {
    width: 100%;
    height: auto;
  }
}
