/* Global Container */
.contactus-container {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

/* Image Section */
.contactus-img-section {
  position: relative;
  width: 100%;
  height: 25vh;
}

.contactus-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contactus-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactus-text-overlay {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

/* Main Section */
.contactus-main-section {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  padding: 50px;
}

.contactus-form-section {
  width: 63%;
}

.contactus-info-section {
  width: 35%;
}

/* Form Section */
.contactus-form-heading {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.contactus-form {
  width: 100%;
  margin: 0 auto;
}

.contactus-form-group {
  margin-bottom: 15px;
}

.contactus-label {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 5px;
}

.contactus-input,
.contactus-textarea {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.contactus-textarea {
  height: 150px;
}

.contactus-input:focus,
.contactus-textarea:focus {
  outline: none;
  border-color: #007bff;
}

.contactus-submit-btn {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
  background-color: #212f3d;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.contactus-submit-btn:hover {
  background-color: #196f3d;
}

.contactus-error {
  color: red;
  font-size: 0.9rem;
}

.contactus-success-message {
  color: green;
  font-size: 1.1rem;
  margin-top: 10px;
}

/* Info Section */
.contactus-info-heading {
  font-size: 1.8rem;
  font-weight: bold;
}

.contactus-address-heading,
.contactus-phone-heading,
.contactus-email-heading,
.contactus-more-info-heading {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 15px;
}

.contactus-address-text,
.contactus-phone-text,
.contactus-email-text,
.contactus-more-info-text {
  font-size: 1rem;
  color: #555;
}

.contactus-load-more-btn {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.contactus-btn {
  padding: 10px 20px;
  background-color: #212f3d;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contactus-btn:hover {
  background-color: #196f3d;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .contactus-main-section {
    flex-direction: column;
    padding: 20px;
  }

  .contactus-form-section,
  .contactus-info-section {
    width: 100%;
  }

  .contactus-img-section {
    height: 20vh;
  }

  .contactus-text-overlay {
    font-size: 1.5rem;
  }

  .contactus-form-heading,
  .contactus-info-heading {
    font-size: 1.5rem;
  }

  .contactus-input,
  .contactus-textarea {
    font-size: 0.9rem;
  }

  .contactus-submit-btn {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contactus-img-section {
    height: 15vh;
  }

  .contactus-text-overlay {
    font-size: 1.2rem;
  }

  .contactus-form-group {
    margin-bottom: 10px;
  }

  .contactus-submit-btn {
    font-size: 0.9rem;
  }
}
