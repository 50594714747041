/* General Header Styles */
.header-containerHead25 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #ffffff;
    font-family: 'Arial', sans-serif;
    font-weight: 500;
    height: 75px;
    color: #000000;
  }
  
  /* Logo Section */
  .logo-sectionHead25 {
    flex: 1;
  }
  
  .logoHead25 {
    max-height: 120px;
    width: 200px;
    padding: 0px;
    margin-top: -22px;

  }
  
 /* Info Section */
.info-sectionHead25 {
    display: flex;
    align-items: center;
    gap: 15px;
    flex: 1;
    justify-content: flex-end;
  }
  
  .mobile-logoHead25 {
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .emailHead25 {
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  /* Mobile View */
  @media (max-width: 768px) {
    .info-sectionHead25 {
      display: none; /* Hide mobile logo and email in mobile view */
    }
  }