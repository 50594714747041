/* PrivacyP.css */

/* General container styles */
.privacy-container-prv5 {
    width: 100%;
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    padding: 0;
    margin: 0;
  }
  
  /* Image Section */
  .image-section-prv5 {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
  }
  
  .banner-image-prv5 {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Content Section */
  .content-section-prv5 {
    max-width: 800px;
    margin: 20px auto;
    padding: 0 15px;
  }
  
  /* Headings */
  .main-heading-prv5 {
    font-size: 28px;
    font-weight: bold;
    color: #000;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .sub-heading-prv5 {
    font-size: 20px;
    font-weight: bold;
    color: #0056b3;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Text styles */
  .text-prv5 {
    font-size: 16px;
    margin-bottom: 15px;
    text-align: justify;
  }
  
  /* Lists */
  .list-prv5 {
    margin-left: 20px;
    padding-left: 10px;
    list-style-type: disc;
  }
  
  .list-prv5 li {
    margin-bottom: 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .content-section-prv5 {
      padding: 0 10px;
    }
  
    .main-heading-prv5 {
      font-size: 24px;
    }
  
    .sub-heading-prv5 {
      font-size: 18px;
    }
  
    .text-prv5 {
      font-size: 14px;
    }
  
    .image-section-prv5 {
      height: 200px;
    }
  }
  