/* Container for the component */
.container-us1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    height: auto;
    width: 100%;
  }
  
  /* Heading styles */
  .heading-us1 {
    font-size: 2rem; /* Reduced heading font size */
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Content container styles */
  .content-container-us1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; /* Vertically align content */
    width: 100%;
    max-width: 1200px;
    gap: 20px; /* Space between items */
  }
  
  /* Left-side text section */
  .text-section-us1 {
    flex: 1;
    min-width: 300px;
    max-width: 50%;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align text content vertically */
  }
  
  .paragraph-us1 {
    font-size: 1.2rem; /* Slightly reduced text size */
    color: #555;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  .load-more-btn-us1 {
    padding: 6px 12px; /* Smaller button size */
    font-size: 0.8rem; /* Reduced button font size */
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    align-self: start; /* Align button to the left */
  }
  
  .load-more-btn-us1:hover {
    background-color: #0056b3;
  }
  
  /* Right-side image section */
  .image-section-us1 {
    flex: 1;
    min-width: 300px;
    max-width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    box-sizing: border-box;
  }
  
  .image-us1 {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .content-container-us1 {
      flex-direction: column; /* Stack sections vertically */
      align-items: center; /* Center-align items */
    }
  
    .text-section-us1,
    .image-section-us1 {
      max-width: 100%; /* Full width for both sections */
    }
  
    .heading-us1 {
      font-size: 1.8rem; /* Adjusted heading size for smaller screens */
    }
  
    .paragraph-us1 {
      font-size: 0.85rem; /* Reduced text size for smaller screens */
    }
  
    .load-more-btn-us1 {
      font-size: 0.75rem; /* Adjusted button size for smaller screens */
      padding: 5px 10px;
    }
  }
  