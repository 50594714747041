/* Main container for the Team component */
.Team-container-T1 {
    width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;
  }
  
  /* Image section styles */
  .image-section-T1 {
    width: 100%;
    position: relative;
  }
  
  .Team-image-T1 {
    width: 100%;
    height: 35vh;
    object-fit: cover;
  }
  
  .Team-heading-T1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 90%;
  }
  
  /* Our Team Section */
  .our-team-section-T1 {
    padding: 40px 20px;
    text-align: center;
    margin-right: 1000px;
    margin-top: -70px;
  }
  
  .our-team-heading-T1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Team Description Section */
  .team-description-section-T1 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
  }
  
  .team-left-image-T1 {
    flex: 1;
    max-width: 50%;
  }
  
  .team-image-T1 {
    width: 100%;
    height: 340px;
  }
  
  .team-right-text-T1 {
    flex: 1;
    max-width: 50%;
    padding: 20px;
  }
  
  .team-text-T1 {
    font-size: 1.2rem;  
    color: #021f1b;
    
  }
  
  /* Professional Staff Section */
  .professional-staff-T1 {
    padding: 40px 20px;
    background-color: #f7f7f7;
  }
  
  .professional-staff-text-T1 {
    font-size: 1.2rem;
    color: #333;
    text-align: center;
    width: 100%;
    margin: 0 auto;
  }
  
  /* Senior Professionals Section */
  .senior-professionals-T1 {
    padding: 40px 20px;
  }
  
  .senior-professionals-text-T1 {
    font-size: 1rem;
    color: #333;
    text-align: center;
    width: 90%;
    margin: 0 auto;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .Team-heading-T1 {
      font-size: 2rem;
    }
  
    .team-description-section-T1 {
      flex-direction: column;
      align-items: center;
    }
  
    .team-left-image-T1, .team-right-text-T1 {
      max-width: 100%;
    }
  
    .team-text-T1 {
      font-size: 1rem;
    }
  
    .professional-staff-text-T1, .senior-professionals-text-T1 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 768px) {
    .Team-heading-T1 {
      font-size: 1.8rem;
    }
  
    .team-description-section-T1 {
      flex-direction: column;
      align-items: center;
    }
  
    .team-left-image-T1, .team-right-text-T1 {
      max-width: 100%;
    }
  
    .team-text-T1, .professional-staff-text-T1, .senior-professionals-text-T1 {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .Team-heading-T1 {
      font-size: 1.5rem;
    }
  
    .team-description-section-T1 {
      flex-direction: column;
      align-items: center;
    }
  
    .team-left-image-T1, .team-right-text-T1 {
      max-width: 100%;
    }
  
    .team-text-T1, .professional-staff-text-T1, .senior-professionals-text-T1 {
      font-size: 0.9rem;
    }
  }
  