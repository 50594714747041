/* General Container */
.containerTr1 {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem;
}

/* Image Section */
.image-sectionTr1 {
    position: relative;
    width: 100%;
    height: 30vh;
    overflow: hidden;
}
.imageTr1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



.Training-heading-crt1{
position: absolute;
top: 45%;
left: 50%;
transform: translate(-50%, -50%);
font-size: 2.5rem;
font-weight: bold;
color: white;
text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
text-align: center;
}


/* Objective Section */
.objective-sectionTr1 {
  padding: 2rem 1rem;
  text-align: left;
}
.headingTr1 {
  color: purple;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}
.subheadingTr1 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}
.textTr1 {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  text-align: justify;
}
.highlighted-textTr1 {
  color: purple;
  font-size: 1.2rem;
  margin: 1rem 0;
  font-weight: bold;
}

/* Table Section */
.table-containerTr1 {
  margin: 2rem 0;
  overflow-x: auto;
}
.info-tableTr1 {
  width: 100%;
  border-collapse: collapse;
}
.table-headingTr1 {
  background-color: #f0f0f0;
  font-weight: bold;
  padding: 1rem;
  text-align: left;
}
.table-dataTr1 {
  padding: 1rem;
  text-align: left;
}

/* Advantages Section */
.advantages-sectionTr1 {
  padding: 2rem 1rem;
}
.advantages-rowTr1 {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin: 2rem 0;
  flex-wrap: wrap;
}
.advantage-itemTr1 {
  flex: 1;
  padding: 1rem;
  text-align: center;
  color: white;
  border-radius: 5px;
  font-size: 1rem;
}
.bg1Tr1 {
  background-color: rgb(14, 80, 67);
}
.bg2Tr1 {
  background-color: rgb(8, 230, 255);
}
.bg3Tr1 {
  background-color: rgb(14, 80, 67);
}

/* Final Section */
.final-sectionTr1 {
  padding: 2rem 1rem;
}
.final-sectionTr1 h2 {
  font-size: 1.8rem;
  color: purple;
  margin-bottom: 1rem;
  text-align: center;
}
.final-sectionTr1 p {
  font-size: 1rem;
  text-align: justify;
  margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .headingTr1,
  .subheadingTr1 {
    font-size: 1.8rem;
  }
  .textTr1 {
    font-size: 0.9rem;
  }
  .advantages-rowTr1 {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .image-sectionTr1 {
    height: 10%; /* Further reduce image height for smaller screens */
  }
  .headingTr1 {
    font-size: 1.5rem;
  }
  .subheadingTr1 {
    font-size: 1.3rem;
  }
  .textTr1 {
    font-size: 0.85rem;
  }
  .advantages-rowTr1 {
    flex-direction: column;
  }
  .advantage-itemTr1 {
    margin-bottom: 1rem;
  }
}

@media (max-width: 480px) {
  .headingTr1,
  .subheadingTr1 {
    font-size: 1.3rem;
  }
  .textTr1 {
    font-size: 0.8rem;
  }
  .info-tableTr1 th,
  .info-tableTr1 td {
    font-size: 0.85rem;
  }
}
