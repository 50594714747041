.home-containerHimg1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh; /* Full viewport height */
  position: relative;
  overflow: hidden;
  /* padding: 25px; */
  top: -28px;
}

.image-sectionHimg1 {
  position: relative;
  width: 100%;
  height: 100%; /* Full height to cover container */
}

.home-imageHimg1 {
  width: 100%;
  height: 100%;
  margin-top: 28px;
  object-fit: cover; /* Ensures image covers the entire area without distortion */
  transition: opacity 1s ease-in-out; /* Smooth transition effect */
  /* border-radius: 3px; */
}

.text-overlayHimg1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5); /* Slight background overlay for readability */
  border-radius: 10px;
}

.main-titleHimg1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: rgb(37, 216, 226);
}

.descriptionHimg1 {
  font-size: 1.1rem;
  line-height: 1.6;
}

/* Responsive Design */
@media (min-width: 768px) {
  .main-titleHimg1 {
    font-size: 2.5rem;
  }

  .descriptionHimg1 {
    font-size: 1.2rem;
  }
}

/* Mobile-specific styles */
@media (max-width: 767px) {
  .home-containerHimg1 {
    height: 80vh; /* Reduce height for mobile view */
  }

  .home-imageHimg1 {
    height: 60%; /* Reduce the image height */
    margin-to5: 0;
  }

  .text-overlayHimg1 {
    font-size: 1.5rem; /* Adjust the overlay text size */
    padding: 15px;
  }

  .main-titleHimg1 {
    font-size: 1.8rem; /* Smaller title for mobile */
  }

  .descriptionHimg1 {
    font-size: 1rem; /* Smaller description text */
  }
}

.popupHimg1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-contentHimg1 {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.popup-headingHimg1 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.form-groupHimg1 {
  margin-bottom: 15px;
}

.form-groupHimg1 label {
  display: block;
  font-size: 1rem;
  margin-bottom: 5px;
}

.form-groupHimg1 input {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-buttonHimg1 {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-buttonHimg1:hover {
  background-color: #0056b3;
}

.close-buttonHimg1 {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #d9534f;
  color: white;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-buttonHimg1:hover {
  background-color: #c9302c;
}
