/* Main container for the Corporate component */
.corporate-container-crt1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  width: 100%;
}

/* Image section styles */
.image-section-crt1 {
  position: relative;
  width: 100%;
  height: 30vh;
  overflow: hidden;
}

.corporate-image-crt1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Heading styles (centered on the image) */
.corporate-heading-crt1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
  text-align: center;
}

/* Text sections */
.text-section-crt1 {
  margin: 20px 0;
  font-size: 1.1rem;
  line-height: 1.6;
  color: #333;
  text-align: justify;
  width: 90%;
  margin-bottom: -20px;
}

/* Recruitment Overview Section */
.recruitment-overview-section-crt1 {
  margin: 30px 0;
  text-align: center;
  width: 100%;
}

.recruitment-heading-crt1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.recruitment-text1-crt1 {
  color: black;
}

.recruitment-text2-crt1 {
  color: blue;
}

/* Five Columns */
.five-columns-crt1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1px;
  width:98%;
}

.column-heading-crt1 {
  background-color: #1a1717;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  flex: 1 1 calc(20% - 20px); /* Responsive column sizing */
  box-sizing: border-box;
}

.column-heading-crt2 {
  background-color: #082c79;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  flex: 1 1 calc(20% - 20px); /* Responsive column sizing */
  box-sizing: border-box;
}

.column-heading-crt3 {
  background-color: #61077e;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  flex: 1 1 calc(20% - 20px); /* Responsive column sizing */
  box-sizing: border-box;
}

.column-heading-crt4 {
  background-color: #557121;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  flex: 1 1 calc(20% - 20px); /* Responsive column sizing */
  box-sizing: border-box;
}

.column-heading-crt5 {
  background-color: #0c1a1e;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  flex: 1 1 calc(20% - 20px); /* Responsive column sizing */
  box-sizing: border-box;
}

/* Bold Text */
.bold-text-crt1 {
  font-weight: bold;
  margin-top: 20px;
  width: 90%;
  text-align: justify;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .corporate-heading-crt1 {
    font-size: 2rem;
  }

  .text-section-crt1 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .corporate-heading-crt1 {
    font-size: 1.5rem;
  }

  .five-columns-crt1 {
    gap: 5px;
  }

  .column-heading-crt1 {
    flex: 1 1 calc(40% - 10px);
  }
}

@media (max-width: 480px) {
  .corporate-heading-crt1 {
    font-size: 1.2rem;
  }

  .text-section-crt1 {
    font-size: 0.9rem;
  }

  .column-heading-crt1 {
    flex: 1 1 calc(100% - 10px);
  }
}
