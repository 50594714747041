/* RecruitmentP.css */

/* Global Styles */
.req55-container {
    width: 100%;
    padding: 50px;
    box-sizing: border-box;
  }
  
  /* Image Section with Text on Top */
  .req55-img-section {
    position: relative;
    width: 100%;
    height: 25vh; /* 25% of the viewport height */
  }
  
  .req55-img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the section fully */
  }
  
  .req55-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .req55-text-overlay {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  
  /* Heading Section */
  .req55-heading-section {
    margin-top: 30px;
  }
  
  .req55-heading-left {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Description Section */
  .req55-description {
    margin-top: 20px;
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
  }
  
  /* Bottom Image Section */
  .req55-bottom-img-section {
    margin-top: 40px;
  }
  
  .req55-bottom-img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .req55-img-section {
      height: 30vh; /* Increase the image height for smaller screens */
    }
  
    .req55-text-overlay {
      font-size: 1.5rem; /* Reduce font size on smaller screens */
    }
  
    .req55-heading-left {
      font-size: 1.5rem; /* Adjust heading font size */
    }
  
    .req55-description {
      font-size: 0.9rem; /* Adjust font size for readability */
    }
  
    .req55-bottom-img {
      height: 200px; /* Reduce bottom image height for smaller screens */
    }
  }
  