.about-containerAb1 {
  padding: 20px;
  text-align: center;
  background-image: url("https://images.pexels.com/photos/434337/pexels-photo-434337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
}

.about-headingAb1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #dbb126 ;
}

.cards-rowAb1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.cardAb1 {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.cardAb1:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.card-logoAb1 {
  text-align: center;
  margin-bottom: 15px;
}

.logo-imageAb1 {
  width: 55px;
  height: 55px;
}

.card-headingAb1 {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #222;
}

.card-descriptionAb1 {
  font-size: 1rem;
  line-height: 1.5;
  color: #555;
  margin-bottom: 20px;
}

.view-detailsAb1 {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background: #007bff;
  text-decoration: none;
  border-radius: 5px;
  transition: background 0.3s;
}

.view-detailsAb1:hover {
  background: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cards-rowAb1 {
    flex-direction: column;
    align-items: center;
  }
  
  .about-containerAb1 {
    margin-top: -190px;
  }
}
