/* WalkingIV.css */

/* Global Styles */
.walking1-container {
    width: 100%;
    padding: 50px;
    box-sizing: border-box; /* Ensure padding does not overflow */
  }
  
  /* Image Section */
  .walking1-img-section {
    position: relative;
    width: 100%;
    height: 30vh; /* 30% of viewport height for better adaptability */
  }
  
  .walking1-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .walking1-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .walking1-text-overlay {
    font-size: 2rem;
    color: white;
    font-weight: bold;
    text-align: center;
  }
  
  /* Text Section */
  .walking1-text-section {
    margin-top: 20px;
  }
  
  .walking1-text-left {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  /* Skills Section */
  .walking1-skills-container {
    display: flex;
    flex-wrap: wrap; /* Allow skills to stack vertically on smaller screens */
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .walking1-skills-left, .walking1-skills-middle, .walking1-skills-right {
    width: 30%;
    margin-bottom: 20px;
  }
  
  .walking1-skills-left ul, .walking1-skills-middle ul, .walking1-skills-right ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .walking1-skills-left li, .walking1-skills-middle li, .walking1-skills-right li {
    margin-bottom: 10px;
  }
  
  /* Hiring Process Section */
  .walking1-hiring-process {
    margin-top: 50px;
  }
  
  .walking1-process-heading {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
  
  .walking1-process-steps {
    display: flex;
    flex-wrap: wrap; /* Stack steps on smaller screens */
    justify-content: space-between;
    margin-top: 30px;
  }
  
  .walking1-box {
    width: 250%; /* Adjust width for better fitting */
    padding: 20px;
    text-align: center;
    font-weight: bold;
    color: white;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .walking1-box-analysis {
    background-color:#27ae60;
  }
  
  .walking1-box-customize {
    background-color: #212f3d;
  }
  
  .walking1-box-develop {
    background-color: #27ae60;
  }
  
  .walking1-box-implement {
    background-color:#212f3d;
  }
  
  .walking1-box-deliver {
    background-color: #27ae60;
  }
  
  /* Hiring Process Description */
  .walking1-process-description {
    width: 60%; /* Adjust width for smaller screens */
    margin-left: 20px;
  }
  
  .walking1-process-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .walking1-process-table td {
    padding: 10px;
    border: 1px solid #ddd;
    height: 40px;
  }
  
  .walking1-bg-color {
    background-color: #2e4053 ;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 768px) {
    .walking1-img-section {
      height: 40vh; /* Adjust height for better visibility */
    }
  
    .walking1-overlay {
      background: rgba(0, 0, 0, 0.7);
    }
  
    .walking1-text-overlay {
      font-size: 1.5rem; /* Smaller text for mobile */
    }
  
    .walking1-text-left {
      font-size: 1.2rem;
    }
  
    .walking1-skills-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .walking1-skills-left, .walking1-skills-middle, .walking1-skills-right {
      width: 100%; /* Full width for skills on mobile */
      margin-bottom: 15px;
    }
  
    .walking1-process-steps {
      flex-direction: column;
      align-items: center;
    }
  
    .walking1-box {
      width: 100%; /* Full width for process steps on mobile */
      margin-bottom: 10px;
    }
  
    .walking1-process-description {
      width: 100%; /* Full width for description on mobile */
      margin-left: 0;
    }
  
    .walking1-process-table td {
      font-size: 0.9rem; /* Smaller font for table on mobile */
    }
  }
  
  @media screen and (max-width: 480px) {
    .walking1-img-section {
      height: 50vh; /* Increase height for mobile */
    }
  
    .walking1-text-overlay {
      font-size: 1.2rem;
    }
  
    .walking1-text-left {
      font-size: 1rem;
    }
  
    .walking1-box {
      padding: 15px;
      font-size: 1rem;
    }
  }
  