/* General Container */
.services-container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-image: url("https://images.pexels.com/photos/434337/pexels-photo-434337.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2");
 
}



/* Heading */
 .services-heading-srv2 {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333; }


/* Services Grid */
.services-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.service-card {
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.service-card:hover {
  transform: translateY(-5px);
}

.service-icon {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #4caf50;
}

.service-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.service-description {
  font-size: 0.9rem;
  color: #555;
  line-height: 1.5;
}

/* Recruitment Ratios Section */
.ratios-section {
  margin-top: 40px;
  text-align: center;
}

.ratios-section h2 {
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #333;
}

.ratio-bar {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.ratio-label {
  flex: 0 0 150px;
  font-size: 1rem;
  color: #011313;
}

.ratio-fill {
  height: 20px;
  flex: 1;
  border-radius: 10px;
  background: #ddd;
}

/* Responsive Design */
@media (max-width: 768px) {
  .service-card {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }

  .ratio-label {
    flex: 0 0 80px;
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .service-card {
    flex: 1 1 100%;
    max-width: 100%;
  }

  .ratio-label {
    flex: 0 0 60px;
    font-size: 0.7rem;
  }
}
