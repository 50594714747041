/* General Styles */
.header-containerDevH {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #242e3d;
  height: 50px;
  font-family: 'cursive', sans-serif;
  font-weight: 500;
}

.navDevH {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.nav-listDevH {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-itemDevH {
  position: relative;
  margin: 0 10px;
  margin-top: -5px;
  padding-bottom: 10px;
}

.nav-linkDevH {
  color: #fff;
  text-decoration: none;
  padding: 10px 15px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-linkDevH:hover {
  background-color: #ffffff;
  color: #242e3d;
  border-radius: 5px;
 
}

.dropdownDevH {
  display: none;
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1;
  min-width: 180px;
  overflow: hidden;
  margin-top: -8px;
}

.nav-itemDevH:hover .dropdownDevH {
  display: block;
}

.dropdown-itemDevH a {
  color: #242e3d;
  text-decoration: none;
  padding: 10px 15px;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-itemDevH a:hover {
  background-color: #f0f0f0;
}

/* Register Button */
.register-buttonDevH {
  display: flex;
  justify-content: flex-end;
}

.btnDevH {
  background-color: #ff6600;
  color: white;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btnDevH:hover {
  background-color: #ff4500;
  transform: scale(1.05);
}

/* Sidebar Icon (Hamburger) */
.sidebar-iconDevH {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: #fff;
}

/* Close Icon (Cancel) */
.close-iconDevH {
  font-size: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #fff;
}

.navDevH.open {
  display: block;
}

.nav-listDevH.open {
  display: block;
}

@media (max-width: 768px) {
  .header-containerDevH {
    flex-wrap: wrap;
    height: 50px;
  }

  /* Sidebar Icon (Hamburger) for Mobile */
  .sidebar-iconDevH {
    display: block;
    cursor: pointer;
  }

  /* Stack navigation items vertically */
  .navDevH {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #242e3d;
    width: 80%; /* Reduced width */
    z-index: 2;
    height: 350px;
  }

  
  .nav-listDevH {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .nav-itemDevH {
    padding: 15px 0;
  }

  .nav-listDevH.open {
    display: block;
  }

  .register-buttonDevH {
    margin-top: 10px;
  }
}
